<template>
    <div>
           <b-card no-body class="border mt-1">
      <b-card-header class="p-1">
      <b-card-title>Top Holders</b-card-title>
    </b-card-header>
    <b-table
     :busy="isBusy"
      :items="items"
      :fields="fields"
      responsive
      striped
      hover
       @row-clicked="getOwner"
       style="width: 100%;" fixed
       class="mb-0"
    >
     <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(address)="data">
        <div class="w-100 text-truncate"   v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
      <template #cell(owner)="data">
        <div class="w-100 text-truncate"  v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
     <template #cell(amount)="data">
        {{(data.value/Math.pow(10,data.item.decimals)).toLocaleString("en-US")}}
      </template>
      <template #cell(percentage)="data">
        {{(((data.item.amount/Math.pow(10,data.item.decimals))/currentsuply)*100).toFixed(2)+'%'}}
      </template>
         <template #cell(value)="data">
        ${{((data.item.amount/Math.pow(10,data.item.decimals))*price).toLocaleString("en-US")}}
      </template>
    </b-table>
  </b-card>

    </div>
</template>
<script>
import {BCard,BCardTitle,BCardHeader,BTable,BSpinner,VBTooltip} from "bootstrap-vue"
const currentday= new Date().toISOString().substring(0, 10);
import Ripple from "vue-ripple-directive"
export default {
      directives: {'b-tooltip': VBTooltip,
   Ripple},
    props:["tokenaddress","currentsuply","price"],
    components:{BCard,BCardTitle,BCardHeader,BTable,BSpinner},
    data(){
        return{
         fields: [{ key: 'address', label: 'Address',thStyle: {width: '20%'} }, 
         {key:'owner',label:'Owner',thStyle: {width: '20%'}}, 
      {label:'Quantity',key:'amount',thStyle: {width: '20%'}},{label:'Percentage',key:'percentage'},
      {label:'Value',key:'value',thStyle: {width: '25%'}}],
      items:[],
       accounts:[],
         totalaccounts:[],
      countacc:0,
      total:0,
      key:0,
          fechas:"2022-01-28 to "+currentday,
       isBusy: false
        }
    },
    mounted(){
        this.getHoldersbyToken()
    },
    methods:{
          getHoldersbyToken:function() {
      const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
    var ddata=[];
    this.isBusy = !this.isBusy;
  axios.get("https://public-api.solscan.io/token/holders?tokenAddress="+vm.tokenaddress+"&offset=0&limit=10",config) .then(async function (response) {
  for (var x = 0; x < response.data.data.length; x++) {
        ddata.push(response.data.data[x].owner);
   }
    await vm.getSplTransfers(ddata);
     vm.accounts=ddata;
    vm.items=response.data.data;
     vm.isBusy = false;
    })
    .catch((err) => {
      console.log(err);
    });
    },

      getSplTransfers:async function(array) {
          this.offsetspl=0;
            const f=this.fechas.split("to");
          const vm=this;
           vm.key=1;
            if (f.length==2) { 
               var fromdate=new Date(f[0].trim());
              var todate=new Date(f[1].trim());
            const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",},};
        //var spl=[];
        var c=0;
          for (let x = 0; x<array.length; x++) {
               const response=await 
               axios.get("https://public-api.solscan.io/account/splTransfers?account="+array[x]+"&fromTime="+(Math.floor(fromdate.getTime()/1000))+
               "&toTime="+(Math.floor(todate.getTime()/1000))+"&offset=0&limit=50",config) 
                for (let y = 0; y < response.data.data.length; y++) {
                   if(response.data.data[y].symbol=="USDC" || response.data.data[y].symbol=="USDT"){
                           //console.log(response.data.data[y]);
                            vm.countacc=0;   
                              break;   
                   }
                   else{
                     vm.countacc=1;  
                   }
                }
                if(vm.countacc==0){
                  vm.totalaccounts.push(c);
                }
                if(vm.countacc==1){
                     c++;
                  vm.totalaccounts.push(c);
                }
                vm.countacc=0;
          }
          vm.total=c;
          vm.key=2;
            }  
    },
     getOwner:function(item){
       let  dataU={owner:item.owner};
       localStorage.setItem('dataAcc',null);
       localStorage.removeItem('dataAcc'); 
       localStorage.setItem('dataAcc',JSON.stringify(dataU));
      this.$router.push({ name: 'account'})
    },
           numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    }
    
}
</script>