<template>
  <b-card>
          <b-card-header>
                <div>
                  <b-card-title>{{title}}</b-card-title> 
                  </div>   
                         <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
          <flat-pickr
          v-model="rangeDate"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
          :config="{ mode: 'range'}"
        />
      </div>
            </b-card-header> 
            <b-card-body>
    <app-echart-bar
      :option-data="option"
    />
    </b-card-body>
  </b-card>
  
</template>

<script>
import { BCard,BCardTitle,BCardHeader,BCardBody } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import flatPickr from 'vue-flatpickr-component';
const currentday= new Date().toISOString().substring(0, 10);

export default {
  props:["title","total","fechas"],
  components: {
    BCard,BCardHeader,BCardTitle,BCardBody,
    AppEchartBar,flatPickr
  },
  data() {
    return {
      rangeDate:this.fechas,
      option: {
        xAxis: [
          {
            data: [this.fechas],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '80px',
          bottom: '30px',
        },
        series: [
          {
            name: 'Total Accounts',
            type: 'bar',
            data: [this.total],
          }
        ],
      },
    }
  },
   watch:{
      rangeDate:function(v){
         this.$root.$emit("cambiofechas",v);
      }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
