<template>
    <div>
          <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <div>
            <b-card-title>Top 100 Dust Wallets</b-card-title>
          </div>
         <div class="d-flex align-items-center">
          <div>
            <b-form-group
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group >
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Filter by Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
          </div>
          <div class="ml-1">
        <feather-icon
          icon="DownloadIcon"
          size="21"
           class="cursor-pointer"
           @click="downloadfile"
            v-b-tooltip.hover.top="'Download Excel'"
        />
        </div>
      </div>
        </b-card-header>
        <b-table
      :items="dustwallets"
      responsive
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
       style="width: 100%;" fixed>
      <template #cell(Owner)="data">
        <div class="w-100 text-truncate" v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
      <template #cell()="data">
        {{numberWithCommas(data.value)}}
      </template>
         <template #cell(Percentage)="data">
          {{data.value+'%'}}
      </template>
         <template #cell(Diff)="data">
          {{numberWithCommas(data.value)}}
      </template>
       <template #cell(Remarks)="data">
          <b-badge variant="light-success" v-if="data.value=='INCREASE'">
            {{data.value}}
          </b-badge>
          <b-badge variant="light-danger" v-if="data.value=='DECREASE'">
            {{data.value}}
          </b-badge>
          <b-badge variant="light-secondary" v-if="data.value=='NO CHANGE'">
            {{data.value}}
          </b-badge>
      </template>
        </b-table>
         <b-col cols="12">
        <b-row class="match-height">
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
          </b-col>
        </b-row>
      </b-col>
      </b-card>
    </div>
</template>
<script>
import {BCard,BCardTitle,BCardHeader,BTable,BPagination,VBTooltip,BRow,BCol,BBadge,
BFormInput,BFormGroup,BInputGroupAppend,BInputGroup,BButton} from "bootstrap-vue"
export default {
       directives: {'b-tooltip': VBTooltip},
    components:{BCard,BCardTitle,BCardHeader,BTable,BPagination,BRow,BCol,BBadge,BFormInput,BFormGroup,
    BInputGroupAppend,BInputGroup,BButton},
    data(){
        return{
        perPage: 25,
        totalRows: 1,
        currentPage: 1,
        dustwallets:[],
        filter: null,
        filterOn: [],
        }
    },
    mounted(){
        this.getDUSTWallets()
    },
    methods:{
         getDUSTWallets:function(){
      const vm=this;
     const axios = require("axios");
     const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.post('/api/v1/sel/tkwhas',{},config).then(function (response) {
    vm.totalRows=response.data.length
    vm.dustwallets=response.data
    })
    .catch((err) => {
      console.log(err);
    });
    },
    downloadfile:async function(){
        var ex=null;
      const axios = require("axios");
(() => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      'Content-Disposition': "attachment; filename=template.xlsx"
    },
       responseType: 'arraybuffer'
  };
  axios.post("/api/v1/sel/tkhdcv",{},config).then(function (response) {
     ex=response.data
      const url = window.URL.createObjectURL(new Blob([ex]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", 'DustHoldersMonitoring.xlsx');
        document.body.appendChild(link);
        link.click();
    })
    .catch((err) => {
      console.log(err.message);
    });})();
    },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
     numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    }
}
</script>