<template>
<div>
    <b-row >
    <b-col>
      <div class="text-right text-primary mb-1" v-if="SocialChannels.twitter">
        <b-link :href="SocialChannels.twitter" target="_blank">
          <feather-icon
            icon="TwitterIcon"
            size="21"
          />
          </b-link>
    </div>
    </b-col>
  </b-row>
  <b-row class="match-height">
       <b-col lg="4" md="6" sm="12" cols="12">
       <CardTransparent
          color=""
          icon=""
          :statistic="tokenname"
          statistic-title="Token Name"
        />
    </b-col>
    <b-col lg="4" md="6" sm="12" cols="12">
       <CardTransparent
          color="danger"
          icon="CalendarIcon"
          :statistic="'$'+price"
          statistic-title="Price"
        />
    </b-col>
    <b-col lg="4" md="6" sm="12" cols="12">
        <CardTransparent
          color=""
          icon=""
          :statistic="numberWithCommas(totalholders)"
          statistic-title="Total Holders"
        />
    </b-col>
  </b-row>
  <b-row class="match-height">
     <b-col lg="4" md="6" sm="12" cols="12">
        <CardTransparent
          color=""
          icon=""
          :statistic="numberWithCommas(currentsuply)"
          statistic-title="Current Supply"
        />
    </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
        <CardTransparent
          color=""
          icon=""
          :statistic="'$'+numberWithCommas(marketcap)"
          statistic-title="Market Cap"
        />
    </b-col>
    <b-col lg="4" md="6" sm="12" cols="12">
        <CardTransparent
          color=""
          icon=""
          :statistic="website"
          statistic-title="Website"
          :web="1"
        />
    </b-col>
  </b-row>
  <component :is="price === undefined ? 'div' : 'b-card'">
  <b-tabs content-class="mt-2" pills>
    <b-tab 
    active>
    <template #title>
          <feather-icon
            icon="UserCheckIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Holders</span>
        </template>
        <token-details-holders :tokenaddress="tokenaddress" :currentsuply="currentsuply" :price="price"/>
    </b-tab>
    <b-tab v-if="tokenname=='DUST Protocol'">
      <template #title>
            <feather-icon
            icon="TrendingUpIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Dust Wallets Analysis</span>
      </template>
      <token-details-wallets/>
    </b-tab>
    <b-tab v-if="tokenname=='DUST Protocol'">
    <template #title>
          <feather-icon
            icon="TrendingUpIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Analysis</span>
        </template>
        <token-details-graphics/>

        <!--b-row class="match-height">
    <b-col lg="6" md="6" sm="12">
       <graphics-analitys :title="'Active holders vs All holders'"/>       
    </b-col>
  </b-row-->

    </b-tab>
  </b-tabs>
  </component>
  </div>
</template>
<script>
import { BTabs, BTab,BCardHeader,BCardText,BRow,BCol,BCard,BCardTitle,VBTooltip
,BLink,BButton,BPagination,BFormSelect,BFormGroup,BBadge} from 'bootstrap-vue'
import GraphicsAnalitys from '../GraphicsAnalitys.vue';
import Ripple from 'vue-ripple-directive'
import EchartBar from '@/components/charts/EchartBar.vue';
//import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import CardTransparent from "@core/components/statistics-cards/StatisticCardHorizontalTransparent.vue" 
import TokenDetailsHolders from "@/views/tokendetails/TokenDetailsHolders.vue"
import TokenDetailsWallets from "@/views/tokendetails/TokenDetailsWallets.vue"
import TokenDetailsGraphics from "@/views/tokendetails/TokenDetailsGraphics.vue"
export default {
  components: {
    BCardText,
    BTabs,
    BTab,BRow,BCol,BCard,BCardTitle,BCardHeader,
    GraphicsAnalitys,EchartBar,CardTransparent,BLink,BButton,
    BPagination,BFormSelect,BFormGroup,BBadge,TokenDetailsHolders,TokenDetailsWallets,TokenDetailsGraphics
  },
  directives: {'b-tooltip': VBTooltip,
   Ripple},
  data(){
        let currentUser =JSON.parse(localStorage.getItem("dataadmin"));
    return{
      tokenaddress:currentUser.tokenaddress,
      price:0,
      tokenname:"",
      totalholders:0,
      currentsuply:0,
      marketcap:0,
      website:"",
      SocialChannels:{}
    }
  },
  mounted(){
      this.getPrice();
      this.getTokenName();
      this.getHolders()
   // this.getDUSTWallets()
    this.$root.$on("cambiofechas", (data) => {
      this.fechas = data;
         const f=this.fechas.split("to");
          if (f.length==2) { 
          //this.getHoldersbyToken();
        }
    });
  },
  methods:{
        getTokenName:function() {
      const vm=this;
     const axios = require("axios");
     const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://public-api.solscan.io/token/meta?tokenAddress="+vm.tokenaddress+"",config) .then(function (response) {
    vm.tokenname=response.data.name;
    vm.marketcap=((response.data.price*response.data.supply)/Math.pow(10,response.data.decimals)).toFixed(2)
    vm.currentsuply=(response.data.supply/Math.pow(10,response.data.decimals)).toFixed(2)
    vm.website=response.data.website
    if (response.data.twitter) {
      vm.SocialChannels.twitter=response.data.twitter
    }
    })
    .catch((err) => {
      console.log(err);
    });
    },
        getPrice:function() {
      const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://public-api.solscan.io/market/token/"+vm.tokenaddress+"",config) .then(function (response) {
        vm.price=response.data.priceUsdt;
    })
    .catch((err) => {
      console.log(err);
    });
    },
      getHolders:function() {
      const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://public-api.solscan.io/token/holders?tokenAddress="+vm.tokenaddress+"&offset=0&limit=10",config) .then(function (response) {
        vm.totalholders=response.data.total
    })
    .catch((err) => {
      console.log(err);
    });
    },
    getOwner:function(item){
       let  dataU={owner:item.owner};
       localStorage.setItem('dataAcc',null);
       localStorage.removeItem('dataAcc'); 
       localStorage.setItem('dataAcc',JSON.stringify(dataU));
      this.$router.push({ name: 'account'})
    },
  numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}

</script>
<style>
</style>