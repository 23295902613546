<template>
  <b-card :title="title">
    <app-echart-stacked-area :allholders="allholders" :activeholders="activeholders"
    :dates="dates"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
    props:["title"],
  components: {
    BCard,
    AppEchartStackedArea,
  },
  data() {
    return {
          allholders:[],
          activeholders:[],
          dates:[],
    }
  },
  mounted(){
     this.getAnalysisbyToken();
  },
  methods:{
getAnalysisbyToken:function(){
  const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://api.solscan.io/token/holder/statistic/total?tokenAddress=So11111111111111111111111111111111111111112",config) .then(function (response) {
    
    for (var x = 0; x < response.data.data.data.totalHolders.length; x++) {
        vm.allholders.push(response.data.data.data.totalHolders[x]['total']);
     
    }
     for (var x = 0; x < response.data.data.data.activeHolders.length; x++) {
        vm.activeholders.push(response.data.data.data.activeHolders[x]['total']);
    }

    for (var x = 0; x < response.data.data.data.totalHolders.length; x++) {
        const milliseconds = response.data.data.data.totalHolders[x]['datetime'] * 1000
        var newdate = new Date(milliseconds);
        var strdata=newdate.getDate()+"/"+(newdate.getMonth()+1);
        vm.dates.push(strdata);  
    }
  
    })
    .catch((err) => {
      // handle error
      console.log(err);
    });

},

  }
}
</script>
