<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/grid'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  props:["allholders","activeholders","dates"],
  components: {
    ECharts,
  },
  data() {
    return {
      option:{
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    icon:'circle',
  },
    toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      }
    }
  },
    grid: {
          right: '80px',
          bottom: '60px',
        },
  xAxis: {
    type: 'category',
    boundaryGap: false,
      splitLine: { show: false },
    data: this.dates
  },
  yAxis: {
    type: 'value',
     boundaryGap: [0, '100%'],
      splitLine: { show: false }
  },

  series: [
    {
      name: 'All Holders',
      type: 'line',
       smooth: true,
       stack: 'Total',
          symbolSize: 10,
      symbol: 'none',
        
      data: this.allholders
    },
    {
      name: 'Active Holders',
      type: 'line',
          symbolSize: 10,
      symbol: 'none',
      data: this.activeholders
    }
  ]
      }
    }
  },

}
</script>
