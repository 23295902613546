<template>
    <div>
        <b-row class="match-height">
             <b-col lg="6" md="12" sm="12">
      <apex-line-area-chart :chartTitle="'Supply History'" :cseries="seriesSupply" 
      :labels="labelsSupply"  :estado="true" :numerograph="0" :key="key"/>
    </b-col>
      <b-col lg="6" md="12" sm="12">
      <apex-multipe-bar-chart :chartTitle="'Daily Volume'" :cseries="seriesVolume" 
      :labels="labelsVolume" :key="key1" :color="['#00E396']" :numerograph="0"/>
    </b-col>
        </b-row>
        
    </div>
</template>
<script>
import {BRow,BCol} from "bootstrap-vue"
import ApexLineAreaChart from "@/components/charts/apex-chart/ApexLineAreaChart.vue"
import ApexMultipeBarChart from "@/components/charts/apex-chart/ApexMultipleBarChart.vue"
export default {
    components:{BRow,BCol,ApexLineAreaChart,ApexMultipeBarChart},
    data(){
        return{
              seriesSupply:[],
              labelsSupply:[],
              seriesVolume:[],
              labelsVolume:[],
              key:0,
              key1:0, 
        }
    },
    mounted(){
        this.getSupply()
        this.getVolume()
        
    },
    methods:{
         getSupply:function(){
      const vm=this;
     const axios = require("axios");
     const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.post('/api/v1/sel/tkwsys',{},config).then(function (response) {
    vm.seriesSupply.push({name:'Supply',data:response.data.info})
    vm.labelsSupply=response.data.labels
    vm.key=1
    })
    .catch((err) => {
      console.log(err);
    });
    },
     getVolume:function(){
      const vm=this;
     const axios = require("axios");
     const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.post('/api/v1/sel/tkwvea',{},config).then(function (response) {
    vm.seriesVolume.push({name:'Volume',data:response.data.info})
    vm.labelsVolume=response.data.labels
    vm.key1=1
    })
    .catch((err) => {
      console.log(err);
    });
    },

    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>